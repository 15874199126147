import React from 'react';
import Layout from 'components/Layout';
import {
  // StaticQuery,
  graphql,
  Link,
  withPrefix
} from 'gatsby';

import logger from 'utils/logger';
// import { Link } from 'gatsby';
import get from 'lodash/get';
import Helmet from 'react-helmet';
import SEO from 'components/SEO/SEO';
import Img from 'components/Elements/Img';
import Header from 'components/Headers/Header';

import { Container, Row, Col } from 'reactstrap';
import { Fluid, Offset } from 'components/Containers';
import Carousel from 'nuka-carousel';

import LoadingView from 'components/Loaders/LoadingView';
import FeaturedProduct from 'components/Cards/FeaturedProduct';
import FeaturedNews from 'components/Cards/FeaturedNews';
// import CardNews from 'components/Cards/CardNews';
import FeaturedCase from 'components/Cards/FeaturedCase';

class Template extends React.Component {
  // constructor () {
  //   super(...arguments);
  //   this.state = { slideIndex: 0, length: 6, wrapAround: true };
  // }
  render () {
    logger.template(__filename, '>', 'AppIndex.render()');
    const siteTitle = get(this, 'props.data.site.siteMetadata.title');
    // const networks = get(this, 'props.data.site.siteMetadata.networks');
    // const posts = get(this, 'props.data.allPrismicDocument.edges');
    const data = get(this, 'props.data');
    logger.gql(data);
    if (!data) { return <LoadingView />; }

    const page = data.contentfulPage;
    const media = data.allContentfulArticle.edges;

    const mediaArticles = [];
    // const mediaCases = [];
    media.forEach((edge) => {
      const article = edge.node;
      if (article.categories && article.categories.length > 0) {
        article.categories.forEach((category) => {
          if (category.slug === `case-studies`) {
            // mediaCases.push(article);
          } else {
            mediaArticles.push(article);
          }
        });
      }
    });
    const mediaCases = data.contentfulPage.featuredCases;

    const headerProps = {
      height: `65vh`,
      bgColor: `indigo-900`,
      // bgImage: withPrefix(`/img/home-header-product.jpg`),
      ui: 70
    };

    const gutter = 8;

    const { featuredProducts } = data.contentfulPage;
    const articles = {
      featuredProducts: [
        {
          productLine: `TAHOMA`,
          productSeries: `T#`,
          bgImage: withPrefix(`/img/header-bg-gl.jpg`),
          thumbnail: 'https://images.ctfassets.net/vd4y94r5n5vv/24YSp3qTVyo4EYWYmCygQu/a65a3c429752a8c2f6e662464b554f1d/T-Sharp-4RU-Front.png?w=800&h=331&q=30',
          slug: `b0c0680a-6eac-4006-8be0-d795a85124a8`,
          href: `/products/tahoma/TSharp`,
          tagline: `Modular Multiviewers`,
          excerpt: `Multi-format (IP, Fiber, 12G / 3G / HD / SD-SDI, CVBS) Modular Multiviewers with Mix and Match I/Os.`,
          light: true
        },
        {
          productLine: `TAHOMA`,
          productSeries: `UE`,
          thumbnail: 'https://images.ctfassets.net/vd4y94r5n5vv/3VANUfIRI4A248Qy8ewiUy/ce10038532ef44a53f08dcb3c34c005e/UE-K_01.png?w=800&h=536&q=30',
          bgImage: withPrefix(`/img/header-bg-gd.jpg`),
          slug: `cc4d5177-ce97-49d9-bf39-cc38d089bfcd`,
          href: `/products/tahoma/UE`,
          tagline: `HDMI 2.0 Multiviewers`,
          excerpt: `4 and 8 Input 4K/UHD HDMI Multiviewer with KVM options.`,
          btnColor: `primary-800`,
          btnClassName: `color-gray-warm-300`,
          dark: true
        }
      ]
    };
    const carouselProps = {
      autoplay: true,
      autoplayInterval: 6000,
      wrapAround: true,
      renderCenterLeftControls: ({ previousSlide }) => (
        <div className={`fs-15em prl-10em d-none d-sm-block`} role="button" onClick={previousSlide}><i className="icon icon-Previous" /></div>
      ),
      renderCenterRightControls: ({ nextSlide }) => (
        <div className={`fs-15em prl-10em d-none d-sm-block`} role="button" onClick={nextSlide}><i className="icon icon-Next2" /></div>
      )
    };
    logger.templateEnd();
    return (
      <Layout>
        <Fluid>
          <SEO />
          <Header {... headerProps}>
            <Carousel {... carouselProps}>
              { page && page.slides.map((slide, i) => {
                logger.verbose(`slide`, slide);
                const colorIndex = slide.backgroundColor ? slide.backgroundColor.substr(slide.backgroundColor.length - 3) : `500`;
                const backgroundImageOpacity = slide.backgroundImageOpacity ? slide.backgroundImageOpacity : `90`;
                let btnColor = `900`;
                if (parseInt(colorIndex, 10) >= 500) {
                  btnColor = `100`;
                }

                return (
                  <div key={slide.id} className={`slideContainer h-${headerProps.height}`}>
                    <div className={`bg-container bg-${slide.backgroundColor}`} />
                    <div className={`bg-container bg-${slide.backgroundColor} alpha-${backgroundImageOpacity}`} style={{ backgroundImage: `url(${`${slide.backgroundImage.file.url}?h=400`})`, backgroundSize: `cover` }} />
                    <div className="content y-center">
                      <Offset>
                        <Col lg={8} xl={5}>
                          {slide.logo ? (
                            <div style={{ position: `relative`, maxHeight: `200px` }}>
                              <Img
                                title={slide.title}
                                alt={slide.title}
                                fixed={slide.logo.fixed}
                                className={`contrast-${slide.backgroundColor}`}
                                style={{
                                  position: `relative`,
                                  top: `0`,
                                  left: `0`,
                                  maxHeight: `200px`,
                                  width: `100%`
                                }}
                                imgStyle={{
                                  top: `50%`,
                                  transform: `translateY(-50%)`,
                                  objectPosition: `center left`,
                                  maxHeight: `180px`,
                                  maxWidth: `250px`
                                }}
                                objFit={`contain`}
                                fadeIn
                              />
                            </div>
                          ) : (
                            <h1 className={`contrast-${slide.backgroundColor} fs-25em fs-lg-40em ls--30em mb-2em lh-11`}>{slide.title}</h1>
                          )}
                          {
                            slide.heading ? (
                              slide.heading.childMarkdownRemark.rawMarkdownBody && <hr />
                            ) : (
                              slide.logo && <hr />
                            )
                          }
                          {
                            slide.heading && (
                              slide.heading.childMarkdownRemark.rawMarkdownBody && (
                                slide.logo ? ([
                                  <p className={`fs-14em fs-lg-16em fw-600 mb-5em ff-sans fw-400 contrast-${slide.backgroundColor}`}>{slide.title}</p>,
                                  <p className={`fs-10em fs-lg-12em ff-sans fw-400 contrast-${slide.backgroundColor}`}>{slide.heading.childMarkdownRemark.rawMarkdownBody}</p>
                                ]) : (
                                  <p className={`fs-12em fs-lg-15em ff-sans fw-400 contrast-${slide.backgroundColor}`}>{slide.heading.childMarkdownRemark.rawMarkdownBody}</p>
                                )
                              )
                            )
                          }
                          {
                            slide.fields.link && (
                              slide.fields.linkExternal ? (
                                <a
                                  href={slide.fields.link}
                                  target={`_blank`}
                                  className={`btn btn-outline-indigo-${btnColor} btn-md color-gray-cold-${btnColor}`}
                                  title={slide.title}
                                  itemProp="url"
                                >
                                  {slide.linkExternalText ? slide.linkExternalText : `Learn More`}
                                </a>
                              ) : (
                                <Link
                                  to={slide.fields.link}
                                  className={`btn btn-outline-indigo-${btnColor} btn-md color-gray-cold-${btnColor}`}
                                >
                                  {slide.linkExternalText ? slide.linkExternalText : `Learn More`}
                                </Link>
                              )
                            )
                          }
                        </Col>
                      </Offset>
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </Header>
          <article>
            <Offset className={`prl-0 pt-${gutter}px`}>
              <section className={`prl-${gutter / 2}px`}>
                <Container fluid>
                  <Row className={`featured-cards`}>
                    { featuredProducts && featuredProducts.map((productSeries) => {
                      return (
                        <Col key={`featuredProducts-${productSeries.slug}`} lg={6} className={`prl-${gutter / 2}px pb-${gutter}px`}>
                          <FeaturedProduct {...productSeries} dark />
                        </Col>
                      );
                    })}
                  </Row>
                </Container>
              </section>
              <section className={`bg-gray-cold-100 pt-20em pb-${gutter}px prl-${gutter / 2}px`}>
                <Container fluid>
                  <Row className={`pb-10em`}>
                    <h1 className={`mrl-auto section-title`}>Latest News</h1>
                  </Row>
                  <Row className={`featured-cards`}>
                    { mediaArticles && mediaArticles.slice(0, 2).map((article, i) => {
                      logger.verbose(`article`, article)
                      return (
                        <Col key={`featuredArticles-${article.slug}`} lg={6} className={`prl-${gutter / 2}px pb-${gutter}px`}>
                          <FeaturedNews {... article} />
                        </Col>
                      );
                    })}
                  </Row>
                  {/*
                  <Row className={`fluid news-cards`}>
                    { mediaArticles && mediaArticles.slice(2, 7).map((article, i) => {
                      const excerpt = article.excerpt ? article.excerpt.excerpt : article.content ? `${article.content.content.substring(0,237)}...` : `...`;
                      return (
                        <Col key={`newsArticles-${article.slug}`} className={`prl-${gutter / 2}px pb-${gutter}px`}>
                          <CardNews {... article} thumbnail={article.thumbnail && article.thumbnail.sizes.src}  excerpt={excerpt} />
                        </Col>
                      );
                    })}
                  </Row>
                  */}
                </Container>
              </section>
              <section className={`pt-20em pb-${gutter}px prl-${gutter / 2}px`}>
                <Container fluid>
                  <Row className={`pb-10em`}>
                    <h1 className={`mrl-auto section-title`}>Featured Cases</h1>
                  </Row>
                  <Row className={`featured-cases pb-${gutter}px`}>
                    { mediaCases && mediaCases.map((article, i) => {
                      let { excerpt } = article;
                      if (!excerpt && article.content) {
                        excerpt = {
                          excerpt: `${article.content.content.substring(0, 246)}...`
                        };
                      }
                      const f = i === 0;
                      const lgCol = f ? 12 : 4;
                      return (
                        <Col
                          key={`featuredCases-${article.slug}`}
                          lg={lgCol}
                          className={`prl-${gutter / 2}px pb-${gutter}px`}
                        >
                          <FeaturedCase
                            {... article}
                            first={f}
                            excerpt={excerpt}
                          />
                        </Col>
                      );
                    })}
                  </Row>
                  <Row>
                    <Col className={`text-center pb-${gutter}px`}>
                      <a href={`/media?category=case-studies`} className={`btn btn-lg btn-outline-gray-warm-200 color-gray-cold-400 color-gray-cold-600-hover`}>Discover more stories</a>
                    </Col>
                  </Row>
                </Container>
              </section>
            </Offset>
          </article>
        </Fluid>
      </Layout>
    );
  }
}

// /*export */const pageQuery = graphql`
export const query = graphql`
  query($slug: String!) {
    site {
      ...SiteMetadata
    }
    allContentfulArticle(
      sort: { fields: [createdAtLegacy], order: DESC}
    ){
      edges{
        node{
          id
          ... Article
        }
      }
    }
    contentfulPage(slug: {eq: $slug}){
      slides{
        id
        title
        fields{
          link
          linkExternal
        }
        heading{
          childMarkdownRemark{
            rawMarkdownBody
          }
        }
        backgroundColor
        backgroundImage{
          file{
            url
          }
          fluid(quality: 30 maxWidth: 800) {
            base64
            aspectRatio
            src
            srcSet
            sizes 
          }
        }
        logo{
          id
          ...Logo
        }
      }
      template{
        template
      }
      featuredProducts{
        id
        ... ProductSeriesBase
        productLine{
          name
          slug
        }
        thumbnail {
          ... Thumbnail
        }
      }
      featuredCases{
        id
        ... Article
      }
    }
  }
`;

export default Template;


// export default ({ children }) => (
//   <StaticQuery
//     query={query}
//     render={data => (
//       <>
//         <Helmet titleTemplate={`%s | ${data.site.siteMetadata.title}`} defaultTitle={data.site.siteMetadata.title} />
//         <Template data={data}>
//           {children}
//         </Template>
//       </>
//     )}
//   />
// );
